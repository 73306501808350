<template>
    <div class="check_list">
        <el-button size="small" type="primary" :disabled="!checked" @click="open" class="button_108px">查看检查情况
        </el-button>
        <el-dialog title="援青干部项目检查日志" :visible.sync="show" class="check_list_dialog" append-to-body width="950px" @close="close">
            <div class="checkItems_table">
                <el-table :data="checkItems" height="100%">
                    <el-table-column type="index" label="序号" align="center"></el-table-column>
                    <el-table-column label="地址" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.address_obj.address}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="show_name" label="检查人" align="center"></el-table-column>
                    <el-table-column property="show_time" label="检查时间" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="operation" style="display:flex;justify-content: center">
                                <el-button type="text" style="margin-right: 10px;" @click="delCheck(scope.row.id)"
                                           v-if="$store.state.user.current_user.userid===scope.row.user_info.userid&&weituo_power.power.edit">
                                    删除
                                </el-button>
                                <checkItemDetail :id="scope.row.id"></checkItemDetail>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <p>第三方项目检查日志</p>
            <div class="checkItems_table">
                <el-table :data="checkItems2" height="100%">
                    <el-table-column type="index" label="序号" align="center"></el-table-column>
                    <el-table-column label="地址" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.address_obj.address}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="show_name" label="检查人" align="center"></el-table-column>
                    <el-table-column property="show_time" label="检查时间" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="operation" style="display:flex;justify-content: center">
                                <el-button type="text" style="margin-right: 10px;" @click="delCheck(scope.row.id)"
                                           v-if="$store.state.user.current_user.userid===scope.row.user_info.userid&&weituo_power.power.edit">
                                    删除
                                </el-button>
                                <checkItemDetail :id="scope.row.id"></checkItemDetail>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import checkItemDetail from '@c/project/check_item_detail/check_item_detail.vue';
    import myPublic from '@/utils/public';

    export default {
        components: {checkItemDetail},
        props: ['project_id', 'checked'],
        data() {
            return {
                show: false, checkItems: [],checkItems2:[],click_del:false,//是否删除了,如果有则关闭时刷新列表
            }
        },
        computed: {
            weituo_power() {
                return this.$store.state.user.current_user.weituo_power
            }
        },
        methods: {
            open() {
                api.project.getCheckItems({project_id: this.project_id, page: 1, pagesize: 200,type:1}).then(res => {
                    if (res.data) {
                        this.checkItems = res.data.lists || [];
                        this.checkItems.forEach(item => {
                            if (myPublic.isJsonString(item.address)) {
                                item.address_obj = JSON.parse(item.address)
                            }
                            if (item.create_time) {
                                item.show_time = item.create_time.slice(0, -3)
                            }
                            item.show_name = '无';
                            if (item.user_info.nickname) {
                                item.show_name = item.user_info.nickname
                            }
                        });
                    }
                })
                api.project.getCheckItems({project_id: this.project_id, page: 1, pagesize: 200,type:2}).then(res => {
                    if (res.data) {
                        this.checkItems2 = res.data.lists || [];
                        this.checkItems2.forEach(item => {
                            if (myPublic.isJsonString(item.address)) {
                                item.address_obj = JSON.parse(item.address)
                            }
                            if (item.create_time) {
                                item.show_time = item.create_time.slice(0, -3)
                            }
                            item.show_name = '无';
                            if (item.user_info.nickname) {
                                item.show_name = item.user_info.nickname
                            }
                        });
                        this.show = true;
                    }
                })
            },
            delCheck(id) {
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, '确定要删除该检查日志吗?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            api.project.checkItemDel({id: id}).then(res => {
                                if (res.data.state == 1) {
                                    this.$message({
                                        message: '检查日志删除成功',
                                        type: 'success'
                                    });
                                    this.click_del=true;
                                    this.open();
                                }
                            })
                            done();
                        } else {
                            done();
                        }
                    }
                }).catch(() => {

                });
            },
            close(){
                if(this.click_del){
                    this.$emit('refresh',true);
                    this.click_del=false;
                }
            }
        }
    }
</script>

<style lang="less">
    div.check_list_dialog {
        .el-dialog {
            /*min-height: 50%;*/
            height:675px;
            max-height: 90%;

            .el-dialog__header {
                border-bottom: none;
            }
            .el-dialog__body{
                .checkItems_table{
                    flex:1;
                    min-height: 0;
                }
            }
            th {
                background-color: #f8f8f8;
                color: #666666;
                font-weight: normal;
                font-size: 14px;
                border: none;
                padding: 12px 0;
            }
            p{
                height:40px;
                line-height: 40px;
                text-align: center;
            }
        }

        .detail_item.table {
            padding: 0 !important;
            margin: 0 !important;
        }
    }
</style>