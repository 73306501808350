<template>
    <div class="upload_report">
        <el-button size="small" type="primary" @click="open" class="button_108px" style="margin-left:10px;">上传技术报告</el-button>
        <el-dialog title="技术报告列表" :visible.sync="show" class="upload_report_dialog" append-to-body width="680px">
            <div class="upload_report_dialog">
                <div class="_top" v-if="weituo_power.power.edit">
                    <div class="month">
                        <div class="left">选择上传月度：</div>
                        <div class="right">
                            <el-date-picker size="small" style="width:180px;" v-model="month" type="month" :editable="false" value-format="yyyy-MM" :clearable="false"></el-date-picker>
                        </div>
                    </div>
                    <el-button size="small" type="primary" class="button_108px" @click="addReport">上传技术报告</el-button>
                </div>
                <el-table :data="list" height="100%" v-loading="loading">
                    <el-table-column label="技术报告名称" align="center">
                        <template slot-scope="scope">
                            <div class="files">
                                <div class="file" v-for="(v,k) in scope.row.file_ids" :key="k+1" @click="previewFile(v)">{{v.fileName}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="上传月度" align="center" width="200px">
                        <template slot-scope="scope">
                            <span>{{scope.row.month}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" v-if="weituo_power.power.edit" width="150px">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="delReport(scope.row.id,scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import myPublic from '@/utils/public.js';
    import dingDisk from '@/utils/ding_disk';
    export default {
        props:['project_id'],
        data() {
            return {
                show: false, list: [],loading:false, month:''
            }
        },
        computed: {
            weituo_power() {
                return this.$store.state.user.current_user.weituo_power
            },
            current_userid(){
                return this.$store.state.user.current_user.userid
            }
        },
        methods: {
            open() {
                let year = new Date().getFullYear();
                let month = new Date().getMonth()+1;
                if(month<10){
                    month='0'+month;
                }
                this.month=year+'-'+month;
                this.reportList();
                this.show = true;
            },
            //上传附件
            addReport() {
                dingDisk.dingDiskUpdate(res => {
                    let param={
                        user_id:this.current_userid,
                        project_id:this.project_id,
                        file_ids:JSON.stringify(res.data),
                        month:this.month
                    }
                    api.project.addReport(param).then(res => {
                        if (res.data&&res.data.state==1) {
                            this.$message({
                                message:'上传技术报告成功',
                                type:'success'
                            })
                            this.reportList(true);
                        }
                    })
                })
            },
            //预览附件
            previewFile(file_info) {
                dingDisk.previewFile(file_info)
            },
            delReport(id,index){
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, '确定要删除该条技术报告吗?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            api.project.delReport({id: id}).then(res => {
                                if (res.data.state == 1) {
                                    this.$message({
                                        message: '技术报告删除成功',
                                        type: 'success'
                                    });
                                    this.list.splice(index,1);
                                }
                            })
                            done();
                        } else {
                            done();
                        }
                    }
                }).catch(() => {

                });
            },
            reportList(refresh=false){
                this.loading=!refresh;
                let param = {
                    page:1,
                    pagesize:999,
                    project_id:this.project_id
                }
                api.project.reportList(param).then(res => {
                    if (res.data) {
                        let list = res.data.lists || [];
                        list.forEach(item=>{
                            if(myPublic.isJsonString(item.file_ids)){
                                item.file_ids=JSON.parse(item.file_ids);
                            }else{
                                item.file_ids=[];
                            }
                        });
                        this.list = list;
                    }
                    this.loading = false;
                })
            }
        }
    }
</script>

<style lang="less">
    .upload_report_dialog{
        height:100%;
        min-height: 500px;
        display:flex;
        flex-direction: column;
        ._top{
            height:70px;
            display:flex;
            align-items: center;
            padding:0 30px;
            .month{
                display:flex;
                align-items: center;
                margin-right: 20px;
            }
        }
        table{
            th{
                background:#f6f6f6!important;
            }
            .files{
                /*display:flex;*/
                /*flex-direction: column;*/
                /*align-items: center;*/
                padding:10px;
                .file{
                    text-align: left;
                    transition:color 0.3s;
                    cursor: pointer;
                    line-height: 16px;
                    margin-bottom: 10px;
                    &:last-of-type{
                        margin-bottom: 0;
                    }

                    &:hover{
                        color:#3396fb;
                    }
                }
            }
        }
    }
</style>